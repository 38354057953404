import moment from 'moment';

export function mCPF(cpf) {
  if (!cpf) return;
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
}

export function mCNPJ(cnpj) {
  if (!cnpj) return;
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function mDate(date) {
  if (!date) return;
  return moment(date).format('DD/MM/YYYY');
}

export function formatDropDawn(value) {
  return (value || []).map((v) => {
    return {
        value: v?.id,
        label: v?.nome || v?.name || v?.cidade,
      }
  });
}
