<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid w-full">
                <div class="text-800 mb-1">
                    <span class="text-primary pi pi-info-circle mb-3" v-tooltip=tooltip></span> {{subtitle}}
                </div>
                <Toast/>

                <div class="">

                    <div class="field">
                        <label for="name">Nome</label>
                        <InputText id="name" v-model.trim="form.name" required="true" autofocus autocomplete="off" :class="{'p-invalid': submitted && !form.name}" />
                    </div>

                    <div class="grid">
                        <div class="field col-5">
                            <label for="classCouncilAcronym">Cidade</label>
                            <MultiSelect
                              v-model="form.city"
                              :filter="true"
                              :options="city"
                              optionLabel="label"
                              :placeholder="this.city?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                              :class="{'p-invalid': submitted && !form.city}"
                              @filter="loadCity"
                              :showClear="true"
                            />
                        </div>
                        <div class="field col-5">
                            <label for="classCouncil">Unidade</label>
                            <MultiSelect
                              v-model="form.unity"
                              :filter="true"
                              :options="unity"
                              optionLabel="label"
                              :placeholder="this.unity?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                              :class="{'p-invalid': submitted && !form.unity}"
                              @filter="loadUnity"
                              :showClear="true"
                            />
                        </div>
                    </div>

                    <div class="field">
                        <label for="specialty">Data de vencimento</label>
                        <InputMask id="specialty" mask="99/99/9999" placeholder="00/00/0000" v-model.trim="form.dueDate" autocomplete="off" :class="{'p-invalid': submitted && !form.dueDate}" />
                    </div>
                    <small class="p-error" v-if="submitted && (!form.name || !form.dueDate || !unity || !city)">Preencha todos os campos.</small>
                </div>

                <div class="flex mt-5">
                    <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                    <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto"  @click="$router.push('/security/routes')"/>
                </div>


            </div>
        </div>
    </div>

</template>

<script>

import SecurityService from '../../services/SecurityService';
import { formatDropDawn } from '../../utils/Mask';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            city: [],
            unity: [],
            timeout: null,
            tooltip: "Cadastro de novas rotas",
            subtitle: "Nova Rota"
        }
    },
    async mounted() {
      this.$service = new SecurityService('/seguranca/rotas');
      this.$serviceUnits = new SecurityService('/customer/branches');
      this.$serviceCities = new SecurityService('/cidades');
      this.load();
    },
    computed: {
        title() {
            return this.$route.params.id
             ? 'Editar rota'
             : 'Adicionar rota'
        }
    },
    methods: {
        async load() {
          // Todas as unidades
          try {
            const responseUnity = await this.$serviceUnits.findAll(null, {});
            const formatUnity = formatDropDawn(responseUnity?.data?.items || []);
            this.unity = formatUnity;
          } catch (error) {
            this.$toast.add({severity:'error', summary: 'Problemas ao listar unidades', life: 3000});
          }
          // Todas as cidades
          try {
            const responseCity = await this.$serviceCities.findAll(null, {});
            const formatCity = formatDropDawn(responseCity?.data?.items || []);
            this.city = formatCity;
          } catch (error) {
            this.$toast.add({severity:'error', summary: 'Problemas ao listar cidades', life: 3000});
          }
        },
        async loadCity(event) {
          if (this.timeout) {
              clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(async () => {
            const params = { filter: [event.value] };
            const { data } = await this.$serviceCities.findAll(null, params);
            const formatCity = formatDropDawn(data?.items || []);
            this.city = formatCity;
          }, 500);
        },
        async loadUnity(event) {
          if (this.timeout) {
              clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(async () => {
            const params = { filter: [event.value] };
            const { data } = await this.$serviceUnits.findAll(null, params);
            const formatUnity = formatDropDawn(data?.items || []);
            this.unity = formatUnity;
          }, 500);
        },
        async save() {
            this.submitted = true;
            try {
              const formatCity = this.form.city?.map((v) => v.value);
              const formatUnity = this.form.unity?.map((v) => v.value);
              const newName = String(this.form.dueDate).split('/');
              const convertISO = new Date(parseInt(newName[2]),parseInt(newName[1])-1,parseInt(newName[0]));
              const body = {
                idsCities: formatCity,
                idsUnits: formatUnity,
                dueDate: convertISO,
                name: this.form.name,
              };
              const stringBody = JSON.stringify(body);
              await this.$service.save(stringBody);
              this.$toast.add({severity:'success', summary: 'Rota salva com sucesso!', life: 3000});
              this.submitted = false;
              this.form = {};
            } catch (error) {
              this.$toast.add({severity:'error', summary: 'Problemas ao salvar a rota!', life: 3000});
            }
        }
    }

}
</script>

<style scoped lang="scss">

</style>
