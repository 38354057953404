import { getClientSecurity } from './http'

export default class SecurityService {

  constructor(path) {
    this.path = path
  }

  save(body, idItem) {
    if (idItem) {
      return getClientSecurity().put(
        `${this.path}/${idItem}`,
        body,
        { headers: { 'Content-Type': 'application/json' } },
      )
    }
    return getClientSecurity().post(
      this.path,
      body,
      { headers: { 'Content-Type': 'application/json' } },
    )
  }

  savePatch(body) {
    if (body.id) {
      return getClientSecurity().patch(
        `${this.path}/${body.id}`,
        body,
        { headers: { 'Content-Type': 'application/json' } },
      )
    }
    return getClientSecurity().post(
      this.path,
      body,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  findById(id) {
    return getClientSecurity().get(`${this.path}/${id}`)
  }

  remove(id) {
    return getClientSecurity().delete(`${this.path}/${id}`)
  }

  findByOneField(value){
    return getClientSecurity().get(`${this.path}/Hierarquia/${value}`)
  }

  findAll(query, params, headers) {
    const { page = 1, limit = 10, last = '', first = '', filter = '', sort = '', ...otherParams } = params || {};
    if (query && Object.keys(query)?.length) {
      return getClientSecurity().get(`${this.path}${query}`, {
        params: {
          page,
          limit,
          last,
          first,
          filter,
          sort,
          ...otherParams,
        },
        headers,
      });
    }

    return getClientSecurity().get(this.path, {
      params: {
        page,
        limit,
        last,
        first,
        filter,
        sort,
        ...otherParams,
      },
      headers,
    });
  }

  startRoute(idRoute) {
    return getClientSecurity().patch(`${this.path}/comecar/${idRoute}`);
  }

  patch(body) {
    return getClientSecurity().patch(
      this.path,
      body,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  put() {
    return getClientSecurity().put(this.path);
  }
}
